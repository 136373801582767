<template>
  <div>
    <b-button v-b-toggle:sidebar variant="primary">
      <b-icon icon="layout-sidebar"></b-icon>
    </b-button>

    <b-sidebar id="sidebar" title="Menu" backdrop shadow bg-variant="dark" text-variant="light">
      <SidebarSection v-for="routeConfig in sidebarConfig" :key="routeConfig.title" :route-config="routeConfig"
        :admin="admin" :external="external" />
      <template #footer="">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2" style="border-top: 1px solid #A4A4A4;">
          <strong class="mr-auto">{{ localStorage.user }}</strong>
          <router-link :to="{ path: '/logout' }">
            <b-button class="btn btn-default" size="sm" href="/logout">
              <b-icon style="color: white;" icon="box-arrow-left"></b-icon>
            </b-button>
          </router-link>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import SidebarSection from "./SidebarSection.vue";

export default {
  name: "Sidebar",
  components: { SidebarSection },
  created() {
    this.$http.get(`/users/infos`).then((response) => {
      this.external = response && response.data && response.data.content && response.data.content.rank == 0;
      this.admin = response && response.data && response.data.content && response.data.content.rank == 3;
    })
  },
  data() {
    return {
      localStorage: localStorage,
      external: true,
      admin: false,
      sidebarConfig: {
        main: {
          external: false,
          needAdmin: false,
          title: 'Main',
          icon: 'box-seam',
          routes: [
            { name: 'Accueil', to: '/' },
            { name: 'DashTag', to: '/dashtag' },
            { name: 'Aide', to: '/help' },
          ],
        },
        matrice: {
          external: false,
          needAdmin: false,
          title: 'Matrice',
          icon: 'grid-3x3-gap',
          routes: [
            { name: 'Analyse Client', to: '/analyse-client' },
            { name: 'CA global', to: '/global-stats' }
          ],
        },
        campaigns: {
          external: false,
          needAdmin: false,
          title: 'Campagnes',
          icon: 'badge-ad',
          routes: [
            { name: 'Performances', to: '/performances' }
          ],
        },
        hotels: {
          external: true,
          needAdmin: false,
          title: 'Hotels',
          icon: 'building',
          routes: [
            { name: `Suivi de l'activité`, to: '/hotels/activity' },
            { name: `Recherche de réservation`, to: '/hotels/bookings/search' },
          ],
        },
        gcmv2: {
          external: false,
          needAdmin: false,
          title: 'Google Consent Mode V2',
          icon: 'building',
          routes: [
            { name: 'Alertes', to: '/google-consent' },
            { name: 'Statistiques', to: '/google-consent-stats' }
          ],
        },
        tools: {
          external: false,
          needAdmin: false,
          title: 'Outils',
          icon: 'hammer',
          routes: [
            { name: 'Générateur de signature', to: '/sign-tool' },
            { name: 'Origines inconnues', to: '/unkownOrigins' },
            { name: 'Agrégation des logs', to: '/agg-logs' },
            { name: 'Leviers non liés', to: '/unlinked-levers' }
          ],
        },
        admin: {
          external: false,
          needAdmin: true,
          title: 'Admin',
          icon: 'shield-shaded',
          routes: [
            { name: 'Admin', to: '/admin' }
          ],
        },
      }
    };
  },
};
</script>
