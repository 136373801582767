<template>
  <div>
    <b-row align-v="center">
      <b-col md="8" offset-md="2">
        <h3>Transfert de levier Google (BETA)</h3>
        <span class="subtitle">Pour le client <b>{{ getCustomer.Login }}</b> : <b>{{ getCustomer.SiteName }}</b></span>
        <hr class="my-4">
        <b-row>
          <b-col md="12">
            <b-alert variant="warning" show>
              <b-form-checkbox v-model="accepted">Transférer un levier Google est une fonctionnalité <b>critique</b>
                pouvant avoir des impacts hasardeux sur des fonctionnalités importantes du Manager Statshop. <b>Vous ne
                  devez utiliser cet outil seulement si nécessaire.</b> Il est conseillé de lancer par la suite une
                synchronisation Google, une réattribution et une récupération des dépenses (demande statistique) sur les
                périodes que vous pensez adaptées en fonction de l’activité des campagnes liées. N’hésitez pas à
                contacter l'équipe technique sur tout doute concernant l’utilisation de cette page.
              </b-form-checkbox>
            </b-alert>
          </b-col>
        </b-row>
        <div v-if="accepted">
          <b-row>
            <b-col md="3">
              <b-button type="button" variant="primary" block @click="createDestTv">Créer un levier</b-button>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col md="6" v-if="levers.length > 0">
              <b-form-input
                  class="mb-3"
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher un levier"
              ></b-form-input>
              <b-table
                  striped hover
                  id="levers-table"
                  empty-text="Aucun levier récupéré!"
                  :items="levers"
                  :fields="fieldsLevers"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  @row-clicked="selectLever"
                  @filtered="onFiltered"
                  stacked="sm"
                  show-empty
                  small
                  fixed
              >
                <template #table-colgroup="scope">
                  <col :key="scope.id"
                       :style="{ width: '60px' }"
                  >
                </template>
              </b-table>
              <b-pagination
                  align="center"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
              ></b-pagination>
            </b-col>
            <b-col md="6" v-if="selected">
              <b-form-input
                  class="mb-3"
                  id="filter-input"
                  v-model="filterDestTvs"
                  type="search"
                  placeholder="Rechercher un levier"
              ></b-form-input>
              <b-table
                  striped hover
                  id="rois-table"
                  empty-text="Aucun levier récupéré!"
                  :items="destTvs"
                  :fields="fieldsDestTvs"
                  :current-page="currentPageDestTvs"
                  :per-page="perPageDestTvs"
                  :filter="filterDestTvs"
                  @row-clicked="selectTv"
                  @filtered="onFilteredTvs"
                  stacked="sm"
                  show-empty
                  small
                  fixed
              >
                <template #table-colgroup="scope">
                  <col
                      :key="scope.id"
                      :style="{ width: '60px' }"
                  >
                </template>
              </b-table>
              <b-pagination
                  align="center"
                  v-model="currentPageDestTvs"
                  :total-rows="totalRowsDestTvs"
                  :per-page="perPageDestTvs"
                  size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-modal id="modal-tv" hide-footer size="md">
      <template #modal-title>
        <b-col md="12">{{ selected.libelle }}</b-col>
        <b-col md="12" class="modal-subtitle">Transférer au levier &laquo; {{ destTv.libelle }} &raquo;</b-col>
      </template>
      <div class="d-block">
        <b-row class="text-center">
          <b-col md="12">
            <b-button type="button" variant="success" block @click="confirmTv">Confirmer</b-button>
            <b-button type="button" variant="warning" block @click="cancelTv">Annuler</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal id="modal-tv-create" hide-footer size="md">
      <template #modal-title>
        <b-col md="12">Création d'un nouveau levier de destination</b-col>
      </template>
      <div class="d-block">
        <b-row class="text-center">
          <b-col md="12">
            <b-form-input
                class="mb-3"
                v-model="newName"
                placeholder="Nom du levier"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col md="12">
            <b-select v-model="newMaster" :options="masters">
              <template #first>
                <div class="mt-3"><strong>Choisir un master levier</strong></div>
              </template>
            </b-select>
          </b-col>
        </b-row>
        <b-row class="text-center mt-3">
          <b-col md="12">
            <b-button type="button" variant="primary" block @click="confirmDestTv">Soumettre</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: 'ForceAttribution',
  data: () => ({
    start_date: '',
    end_date: '',
    levers: [],
    fieldsLevers: [
      {key: 'id', label: '#', sortable: true},
      {key: 'libelle', label: 'Nom', sortable: true},
      {key: 'parent', label: 'Parent', sortable: true},
      {key: 'master', label: 'Master', sortable: true}
    ],
    fieldsDestTvs: [
      {key: 'id', label: '#', sortable: true},
      {key: 'libelle', label: 'Nom', sortable: true},
      {key: 'master', label: 'Master', sortable: true},
      {key: 'account', label: 'Compte', sortable: true},
    ],
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    filter: null,
    selected: null,
    destTvs: [],
    destTv: null,
    filterDestTvs: null,
    perPageDestTvs: 10,
    currentPageDestTvs: 1,
    totalRowsDestTvs: 1,
    accepted: false,
    newName: null,
    newMaster: null,
    masters: [],
    mastersComplete: []
  }),
  mounted() {
    this.start_date = dayjs().subtract(7, 'days').format('YYYY-MM-DD')
    this.end_date = dayjs().format('YYYY-MM-DD')
  },
  created() {
    this.$http.get(`/users/infos`).then((response) => {
      this.log = response ? response.data.content.log : null
    });
    this.loadLevers().then((_) => {
      console.log('Levers loaded');
    }).catch((_) => {
      console.log('Levers failed to load');
    });
    this.loadMasters().then((_) => {
      console.log('Master levers loaded');
    }).catch((_) => {
      console.log('Master levers failed to load');
    });
  },
  methods: {
    async loadLevers() {
      const result = await this.$http.get(`/transfer/${this.getCustomer.Login}/campaigns`);
      this.levers = result ? result.data.content : [];
      this.totalRows = this.levers.length;
    },
    async loadMasters() {
      const result = await this.$http.get(`/transfer/${this.getCustomer.Login}/masters`);
      this.mastersComplete = result.data.content;
      this.masters = result ? [{
        value: null,
        text: 'Sélectionnez un master levier',
        disabled: true
      }].concat(this.mastersComplete.map(e => ({value: e.id, text: e.libelle}))) : [];
    },
    async selectLever(row) {
      this.levers.forEach(row => row._rowVariant = null)
      row._rowVariant = 'warning'
      this.selected = row
      const result = await this.$http.get(`/transfer/${this.getCustomer.Login}/accounts?parent=${row.parent_id}&account=${row.account_id}`);
      this.destTvs = result ? result.data.content : []
      this.totalRowsDestTvs = this.destTvs.length
    },
    async selectTv(row) {
      this.destTv = row
      this.$bvModal.show('modal-tv')
    },
    async createDestTv() {
      this.$bvModal.show('modal-tv-create')
    },
    async confirmTv() {
      await this.$http.patch(`/transfer/${this.getCustomer.Login}`, {
        id: this.selected.id,
        destParent: this.destTv.id,
      })
      this.$bvModal.hide('modal-tv')
      this.$bvToast.toast('Modification réussie.', {
        title: null,
        noCloseButton: true,
        variant: 'success',
        solid: true
      });
    },
    async confirmDestTv() {
      try {
        console.log(this.mastersComplete)
        console.log(this.newMaster)
        const selectedMaster = this.mastersComplete.find((e) => e.id === this.newMaster);
        console.log(selectedMaster)
        await this.$http.post(`/transfer/${this.getCustomer.Login}/dest`, {
          master: selectedMaster.id,
          libelle: this.newName,
          refCom: selectedMaster.ref_com,
          billable: selectedMaster.billable,
          priority: selectedMaster.source_priority,
          employee: selectedMaster.employee_user_id,
        })
        this.$bvModal.hide('modal-tv-create')
        this.$bvToast.toast('Création confirmée.', {
          title: null,
          noCloseButton: true,
          variant: 'success',
          solid: true
        });
        await this.loadLevers();
      } catch (e) {
        console.warn(e);
        this.$bvToast.toast('Modification échouée.', {
          title: null,
          noCloseButton: true,
          variant: 'danger',
          solid: true
        });
      }

    },
    cancelTv() {
      this.$bvModal.hide('modal-tv')
    },
    onFiltered(filteredData) {
      this.totalRows = filteredData.length
      this.currentPage = 1
    },
    onFilteredTvs(filteredTvs) {
      this.totalRowsDestTvs = filteredTvs.length
      this.currentPageDestTvs = 1
    }
  },
  computed: {
    getCustomer() {
      return this.$store.getters.getCustomer
    }
  }
}
</script>
